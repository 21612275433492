/**
 * Shim for @gatsbyjs/reach-router/lib/history
 * This provides the minimum functionality needed
 */

const createHistory = (source) => {
  const history = {}
  history.navigate = (to, options) => {}
  history.createHref = (to) => to
  return history
}

exports.createHistory = createHistory
exports.createMemorySource = (initialPath) => {
  return {
    history: { initialPath },
  }
}

exports.globalHistory = {
  listen: (listener) => {
    return () => {}
  },
  navigate: (to) => {},
  location: { pathname: '/' },
}
