module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_gatsby@4.25.7_babel-eslint@10.1.0_eslint@9.23.0__encoding_eeacfa99cb181dc1bf93633a542a1a3e/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"biassync","short_name":"biassync","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/images/logo-small.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"06c7d683e82d39ed01523a8298811484"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-segment-js@3.7.2_gatsby@4.25.7_babel-eslint@10.1.0_eslint@9.23.0__encodin_cdadb777386b46ac79d8fdf2803ca514/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"2gm2vOUMNaN0MWSbIHxltyPgF4cb5BXH","devKey":"2gm2vOUMNaN0MWSbIHxltyPgF4cb5BXH","trackPage":true,"delayLoad":true,"delayLoadTime":1000,"manualLoad":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-facebook-pixel@1.0.8/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"313157726751625"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-hubspot@2.0.0_gatsby@4.25.7_babel-eslint@10.1.0_eslint@9.23.0__encoding@0_a589cb77d2d0527e5c22a8ee12c4865b/node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"21031833","respectDNT":false,"productionOnly":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-transition-link@1.20.5_@babel+core@7.26.10_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.25.7_babel-eslint@10.1.0_eslint@9.23.0__encoding@0.1.13_react-dom@18.2.0_react_fbb58bc15ab5953f9b2380aa2573c22d/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
