/**
 * Shim for @gatsbyjs/reach-router/lib/utils
 * This provides the minimum functionality needed
 */

exports.pick = (routes, uri) => {
  if (!routes || routes.length === 0) {
    return null
  }
  // Make sure the route object includes the originalPath property
  // which is expected by Gatsby's find-path.js
  return {
    route: {
      ...routes[0],
      originalPath: routes[0].path,
    },
    params: {},
    uri,
  }
}

exports.match = (path, uri) => {
  return { path, uri, params: {} }
}

exports.resolve = (to, base) => {
  return to
}

exports.insertParams = (path, params) => {
  return path
}

exports.validateRedirect = (from, to) => {
  return { from, to }
}
