exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accessibility-policy-tsx": () => import("./../../../src/pages/accessibility-policy.tsx" /* webpackChunkName: "component---src-pages-accessibility-policy-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-inclusivity-roadmap-tsx": () => import("./../../../src/pages/inclusivity-roadmap.tsx" /* webpackChunkName: "component---src-pages-inclusivity-roadmap-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsletter-signup-tsx": () => import("./../../../src/pages/newsletter-signup.tsx" /* webpackChunkName: "component---src-pages-newsletter-signup-tsx" */),
  "component---src-pages-people-tsx": () => import("./../../../src/pages/people.tsx" /* webpackChunkName: "component---src-pages-people-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-request-demo-tsx": () => import("./../../../src/pages/request-demo.tsx" /* webpackChunkName: "component---src-pages-request-demo-tsx" */),
  "component---src-pages-sales-leads-tsx": () => import("./../../../src/pages/sales-leads.tsx" /* webpackChunkName: "component---src-pages-sales-leads-tsx" */),
  "component---src-pages-terms-2023-11-15-tsx": () => import("./../../../src/pages/terms-2023-11-15.tsx" /* webpackChunkName: "component---src-pages-terms-2023-11-15-tsx" */),
  "component---src-pages-terms-2023-12-4-tsx": () => import("./../../../src/pages/terms-2023-12-4.tsx" /* webpackChunkName: "component---src-pages-terms-2023-12-4-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

